<template>
  <v-app id="inspire">
    <div class="px-4">
      <loading
        :is-show="loadingDialog"
        :is-done="loadingFinished"
        :is-html="isHtml"
        :message="loadingMessage"
        v-on:close-dialog="closeDialog"
      />
      <details-form
        :is-show="isShowDetails"
        :details="details"
        v-on:close-dialog="closeDialog"
      />
      <v-container class="container--fluid grid-list-md text-center">
        <h1 data-cy="wallet-title">Wallets</h1>
      </v-container>
      <v-card elevation="2" outlined shaped tile class="pa-1">
        <div class="d-flex flex-no-wrap justify-space-between">
          <v-card-title>
            <v-container grid-list-md text-xs-center>
              <v-layout wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    data-cy="search-wallet"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-btn
                    :block="$vuetify.breakpoint.mdAndDown"
                    class="mt-2"
                    color="success"
                    data-cy="create-wallet"
                    @click.stop="showSavingsProductForm('CREATED')"
                    v-if="hasPermission('ROLE_CREATE_WALLET')"
                    >Create Wallet</v-btn
                  >
                  <savings-product-form
                    ref="form"
                    :selected-item="selectedSavingsProduct"
                    :is-show="showFormDialog"
                    :form-type="savingsProductFormType"
                    :types="savingsProductTypes"
                    :wallets="displaySavingsProduct"
                    v-on:close-dialog="closeDialog"
                    v-on:save-savings-product="saveSavingsProduct"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-title>
        </div>
      </v-card>

      <v-data-table
        :hide-default-header="true"
        :headers="headers"
        :items="displaySavingsProduct"
        :search="search"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalSavingsProduct.length"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr :colspan="headers.length">
              <th v-for="header in headers" :key="header.text">
                <span class="d-flex justify-center">{{ header.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr :colspan="headers.length" v-for="item in items" :key="item.id">
              <td>
                <span class="d-flex justify-center"> {{ item.id }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.shortName }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.name }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.accountType }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.autoApproval }} </span>
              </td>
              <td>
                <span class="d-flex justify-center">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="green"
                    class="mr-2"
                    @click.stop="viewDetails(item)"
                  >
                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    dark
                    x-small
                    color="green"
                    @click.stop="showSavingsProductForm('UPDATE', item)"
                    v-if="hasPermission('ROLE_UPDATE_WALLET')"
                  >
                    <v-icon dark medium>mdi-pencil</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Found no results.
          </v-alert>
        </template>
      </v-data-table>
    </div>
  </v-app>
</template>

<script>
import API from "@/api/API.js";
import Loading from "@/views/components/Loading.vue";
import SavingsProductForm from "@/views/components/SavingsProductForm.vue";
import DetailsForm from "@/views/components/DetailsForm.vue";

import {
  CURRENCIES,
  INTEREST_COUMPOUNDING_PERIODS,
  INTEREST_POSTING_PERIODS,
  INTEREST_CALCULATION_METHODS,
  DAYS_IN_YEAR_OPTIONS,
} from "@/constants/savings_product.js";

import { mapGetters } from "vuex";

export default {
  name: "SavingsProducts",
  components: {
    Loading,
    SavingsProductForm,
    DetailsForm,
  },
  data() {
    return {
      //filter/search variables
      search: "",
      filterQry: {
        qryName: "",
      },
      headers: [],
      savingsProductTypes: [],
      displaySavingsProduct: [],
      remainderSavingsProducts: [],
      loading: true,
      loadingMessage: "",
      loadingDialog: false,
      loadingFinished: false,
      savingsProductFormType: "CREATE",
      selectedSavingsProduct: {},
      paymentMethods: [],
      page: 1,
      totalSavingsProduct: 0,
      numberOfPages: 0,
      options: {
        rowsPerPage: 5,
      },
      details: {
        title: "",
        item: {},
      },
      query: {
        code: "",
        name: "",
      },
      isShowDetails: false,
      showFormDialog: false,
      isHtml: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.setData();
        this.queryData();
      },
    },
    deep: true,
  },
  computed: {
    //get role attributes
    ...mapGetters(["attributes", "user", "authorities"]),
  },
  async mounted() {
    this.setHeaders();
    //this.queryData();
  },
  methods: {
    hasPermission(authority) {
      if (this.authorities.some((x) => x === authority)) {
        return true;
      }
      return false;
    },
    async queryData() {
      this.loading = true;
      try {
        const savingsProductListResponse = await API.getSavingsProducts();
        console.log(savingsProductListResponse);
        if (!savingsProductListResponse || savingsProductListResponse.error) {
          //error getting data
          console.log(`${savingsProductListResponse.error}`);
        } else {
          this.totalSavingsProduct = savingsProductListResponse.length;
          this.displaySavingsProduct = savingsProductListResponse;
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async setData() {
      try {
        const listResponse = await API.getSavingProductType();
        if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`);
        } else {
          // convert object to array
          this.savingsProductTypes = [
            ...new Set(
              listResponse.walletTypes.map((item) => {
                if (item.isEnabled) {
                  return item.name;
                }
              })
            ),
          ];
        }
      } catch (e) {
        console.log(e);
      }
    },
    setHeaders() {
      var headers = [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Short Name",
          value: "shortName",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Account Type",
          value: "accountType",
        },
        {
          text: "Auto Approval",
          value: "autoApproval",
        },
        {
          text: "Actions",
          value: "controls",
          sortable: false,
        },
      ];
      this.headers = headers;
    },
    showSavingsProductForm(what, item = {}) {
      this.savingsProductFormType = what;
      this.selectedSavingsProduct = item;
      this.showFormDialog = true;
    },
    closeDialog(type) {
      if (type === "details") {
        this.details.title = "";
        this.details.item = {};
        this.isShowDetails = false;
      } else if (type === "CREATED" || type === "UPDATE") {
        this.showFormDialog = false;
      } else if (type === "loading") {
        this.loadingDialog = false;
        this.$router.go(0);
      } else if (type === "loading-error") {
        this.loadingDialog = false;
      }
    },
    async saveSavingsProduct(value) {
      this.loadingDialog = true;
      this.isHtml = true;
      this.loadingFinished = false;
      if (value) {
        if (value.formType === "CREATED") {
          this.loadingMessage = `Creating new wallet <strong><i>${value.name}</i></strong>`;
          setTimeout(async () => {
            const createResponse = await API.createSavingsProducts(value);
            this.loadingFinished = true;
            if (!createResponse || createResponse.error) {
              this.isHtml = false;
              this.loadingMessage = createResponse.error.message;
            } else {
              this.loadingMessage = `Successfully created wallet <strong><i>${value.name}</i></strong>`;
              this.showFormDialog = false;
              this.$refs.form.resetForm();
            }
          }, 2000);
        } else if (value.formType === "UPDATE") {
          this.loadingMessage = `Updating wallet <strong><i>${value.name}</i></strong>`;
          setTimeout(async () => {
            const updateResponse = await API.updateSavingsProducts(value);
            this.loadingFinished = true;
            if (!updateResponse || updateResponse.error) {
              this.isHtml = false;
              this.loadingMessage = updateResponse.error.message;
            } else {
              this.loadingMessage = `Successfully updated wallet <strong><i>${value.name}</i></strong>`;
              this.showFormDialog = false;
              this.$refs.form.resetForm();
            }
          }, 2000);
        } else {
          this.loadingMessage = "Invalid command";
          this.loadingFinished = true;
        }
      } else {
        this.loadingMessage = "Error processing data.";
        this.loadingFinished = true;
      }
    },
    viewDetails(details) {
      this.details.title = details.externalId;
      this.details.item = Object.assign({}, details);

      const currency = CURRENCIES.find((x) => x.value === this.details.item.currency);
      const compounding = INTEREST_COUMPOUNDING_PERIODS.find(
        (x) => x.value === this.details.item.interestCompoundingPeriod
      );
      const posting = INTEREST_POSTING_PERIODS.find(
        (x) => x.value === this.details.item.interestPostingPeriod
      );
      const method = INTEREST_CALCULATION_METHODS.find(
        (x) => x.value === this.details.item.interestCalculationMethod
      );
      const daysInYear = INTEREST_CALCULATION_METHODS.find(
        (x) => x.value === this.details.item.daysInYear
      );
      this.details.item.currency = currency ? currency.text : this.details.item.currency;
      this.details.item.interestCompoundingPeriod = compounding
        ? compounding.text
        : this.details.item.interestCompoundingPeriod;
      this.details.item.interestPostingPeriod = posting
        ? posting.text
        : this.details.item.interestPostingPeriod;
      this.details.item.interestCalculationMethod = method
        ? method.text
        : this.details.item.interestCalculationMethod;
      this.details.item.daysInYear = daysInYear
        ? daysInYear.text
        : this.details.item.daysInYear;
      this.isShowDetails = true;
    },
    // search(event, type) {
    //     this.query[type] = event
    // }
  },
};
</script>

<style scoped>
.v-data-table {
  max-width: 100%;
  margin-bottom: 50px;
}
</style>
