"use strict"

const _ = require('lodash');

export const CURRENCIES = [
  { text: "US Dollar", value: "USD" },
  { text: "Philippine Peso", value: "PHP" },
];

export const INTEREST_COUMPOUNDING_PERIODS = [
  { text: "Daily", value: 1 },
  { text: "Weekly", value: 2 },
  { text: "Bi-Weekly", value: 3 },
  { text: "Monthly", value: 4 },
  { text: "Quarterly", value: 5 },
  { text: "Semi-Annual", value: 6 },
  { text: "Annually", value: 7 },
];

export const INTEREST_POSTING_PERIODS = [
  { text: "Monthly", value: 4 },
  { text: "Quarterly", value: 5 },
  { text: "BiAnnual", value: 6 },
  { text: "Annually", value: 7 },
];

export const INTEREST_CALCULATION_METHODS = [
  { text: "Daily Balance", value: 1 },
  { text: "Average Daily Balance", value: 2 },
];

export const DAYS_IN_YEAR_OPTIONS = [
  { text: "360 Days", value: 360 },
  { text: "365 Days", value: 365 },
];

export const LOCKIN_PERIOD_FREQUENCY_TYPE = [
  { text: "Days", value: 0 },
  { text: "Weeks", value: 1 },
  { text: "Months", value: 2 },
  { text: "Years", value: 3 },
];
