<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card ref="formCard">
        <v-card-title>
          <span class="headline" v-if="formType === 'UPDATE'">Update Wallet</span>
          <span class="headline" v-else>Create New Wallet</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formEntry">
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">Details</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  v-if="form.accountType === 'CREDIT'"
                  :complete="e1 > 2"
                  step="2"
                  >Addt'l Details</v-stepper-step
                >
                <v-divider v-if="form.accountType === 'CREDIT'"></v-divider>
                <v-stepper-step
                  v-if="form.accountType === 'CREDIT'"
                  :complete="e1 > 3"
                  step="3"
                  >Currency</v-stepper-step
                >
                <v-divider v-if="form.accountType === 'CREDIT'"></v-divider>
                <v-stepper-step
                  v-if="form.accountType === 'CREDIT'"
                  step="4"
                  :complete="isFormComplete"
                  >Terms</v-stepper-step
                >
                <v-stepper-step
                  v-if="form.accountType !== 'CREDIT'"
                  :complete="e1 > 2"
                  step="2"
                  >Currency</v-stepper-step
                >
                <v-divider v-if="form.accountType !== 'CREDIT'"></v-divider>
                <v-stepper-step
                  v-if="form.accountType !== 'CREDIT'"
                  step="3"
                  :complete="isFormComplete"
                  >Terms</v-stepper-step
                >
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form ref="formEntryOne">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Name*"
                            hint="A unique identifier for the wallet"
                            clearable
                            hide-details="auto"
                            v-model="form.name"
                            data-cy="name"
                            required
                            :rules="[rules.requiredField]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm4>
                          <v-text-field
                            label="Short Name*"
                            clearable
                            hide-details="auto"
                            counter
                            maxlength="4"
                            hint="Unique; Maximum of four characters"
                            v-model="form.shortName"
                            data-cy="short-name"
                            required
                            :rules="[rules.requiredField, rules.maxLength]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            hint="Provide additional information regarding the purpose and characteristic of the wallet"
                            label="Description"
                            clearable
                            hide-details="auto"
                            v-model="form.description"
                            data-cy="description"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            hint="Icon that represents the wallet"
                            label="Image URL"
                            clearable
                            hide-details="auto"
                            v-model="form.imageUrl"
                            data-cy="imageUrl"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-autocomplete
                            v-model="form.accountType"
                            data-cy="account-type"
                            :items="productTypes"
                            item-text="text"
                            item-value="value"
                            label="Account Type*"
                            required
                            :rules="[rules.requiredSelection]"
                          ></v-autocomplete>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <div class="text-right stepper-1-btns">
                      <v-btn color="primary" @click="next(1)">Continue</v-btn>
                    </div>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content v-if="form.accountType === 'CREDIT'" step="2">
                  <v-form ref="formEntryFour">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-dialog
                          data-cy="linked-loading-dialog"
                          v-model="loading"
                          persistent
                          hide-overlay
                          max-width="400px"
                        >
                          <v-card color="primary" dark>
                            <v-card-text>
                              Please stand by
                              <v-progress-linear
                                indeterminate
                                color="white"
                                class="mb-0"
                              ></v-progress-linear>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                        <template>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <v-row>
                                  <v-col cols="5" md="4">Jobs</v-col>
                                  <v-col cols="5" md="4">Linked Wallets</v-col>
                                  <v-col cols="2" md="2">
                                    <v-btn
                                      fab
                                      dark
                                      x-small
                                      color="green"
                                      class="mr-2"
                                      data-cy="add-wallet"
                                      v-if="formType === 'CREATED'"
                                      @click.stop="addLinkedWallets()"
                                    >
                                      <v-icon dark medium>mdi-plus</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </thead>
                              <tbody data-cy="linked-body">
                                <v-row
                                  v-for="(n, index) in linkedWalletFields"
                                  :key="index"
                                >
                                  <v-col cols="5" md="4">
                                    <v-autocomplete
                                      v-model="form.jobs[n]"
                                      :items="availableLinkedJobs[n]"
                                      no-filter
                                      @click="jobClicked(n)"
                                      item-text="jobName"
                                      item-value="id"
                                      required
                                      multiple
                                      data-cy="linked-jobs"
                                      :rules="[rules.requiredSelection]"
                                    ></v-autocomplete>
                                  </v-col>

                                  <v-col cols="5" md="4">
                                    <v-autocomplete
                                      v-model="form.linkedWallets[n]"
                                      :items="linkedWallets"
                                      item-text="name"
                                      item-value="id"
                                      required
                                      data-cy="linked-wallets"
                                      multiple
                                      :rules="[rules.requiredSelection]"
                                    ></v-autocomplete>
                                  </v-col>

                                  <v-col
                                    cols="2"
                                    md="2"
                                    v-if="
                                      linkedWalletFields === n && linkedWalletFields > 1
                                    "
                                  >
                                    <v-btn
                                      fab
                                      dark
                                      x-small
                                      color="red"
                                      class="mr-2"
                                      data-cy="remove-wallet"
                                      v-if="formType === 'CREATED'"
                                      @click.stop="removeLinkedWallets()"
                                    >
                                      <v-icon dark medium>mdi-close</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </template>
                      </v-layout>
                    </v-container>
                    <v-flex class="text-right stepper-2-btns">
                      <v-btn class="mr-2" @click="e1 = 1" color="warning">Back</v-btn>
                      <v-btn color="primary" @click="next(2)">Continue</v-btn>
                    </v-flex>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content :step="form.accountType === 'CREDIT' ? '3' : '2'">
                  <v-form ref="formEntryTwo">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          <v-select
                            label="Currency*"
                            :items="currencies"
                            item-text="text"
                            item-value="value"
                            clearable
                            data-cy="currency"
                            hide-details="auto"
                            hint="The currency to be used"
                            v-model="form.currency"
                            required
                            :rules="[rules.requiredField]"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            autocomplete="off"
                            min="0"
                            label="Decimal Places*"
                            hint="The number of decimal places to be used to track and report on accounts"
                            clearable
                            hide-details="auto"
                            v-model="form.decimalPlaces"
                            type="number"
                            data-cy="decimal-places"
                            required
                            :rules="[rules.requiredField, rules.positiveIntegers]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            autocomplete="off"
                            min="0"
                            label="Multiples Of*"
                            hint="The amount to be rounded off"
                            clearable
                            hide-details="auto"
                            data-cy="multiples-of"
                            v-model="form.multiplesOf"
                            type="number"
                            required
                            :rules="[rules.requiredField, rules.positiveIntegers]"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex class="text-right stepper-2-btns">
                      <v-btn
                        class="mr-2"
                        @click="form.accountType === 'CREDIT' ? (e1 = 2) : (e1 = 1)"
                        color="warning"
                        >Back</v-btn
                      >
                      <v-btn
                        color="primary"
                        @click="form.accountType === 'CREDIT' ? next(3) : next(2)"
                        >Continue</v-btn
                      >
                    </v-flex>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content :step="form.accountType === 'CREDIT' ? '4' : '3'">
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        <v-text-field
                          autocomplete="off"
                          label="Nominal annual interest*"
                          hint="The default interest rate set when creating accounts"
                          clearable
                          hide-details="auto"
                          v-model="form.nominalAnnualInterest"
                          type="number"
                          data-cy="nominal-annual-interest"
                          required
                          :rules="[rules.requiredField]"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-select
                          label="Interest compounding period*"
                          :items="interestCompoundingPeriods"
                          item-text="text"
                          item-value="value"
                          clearable
                          data-cy="interest-compounding-period"
                          hide-details="auto"
                          hint="The period of which the interest rate is compounded"
                          v-model="form.interestCompoundingPeriod"
                          required
                          :rules="[rules.requiredField]"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-select
                          label="Interest posting period*"
                          :items="interestPostingPeriods"
                          data-cy="interest-posting-period"
                          item-text="text"
                          item-value="value"
                          clearable
                          hide-details="auto"
                          hint="The period of which the interest rate is posted or credited to a account"
                          v-model="form.interestPostingPeriod"
                          required
                          :rules="[rules.requiredField]"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-select
                          label="Interest calculated using*"
                          :items="interestCalculationMethods"
                          item-text="text"
                          data-cy="interest-calculated-period"
                          item-value="value"
                          clearable
                          hide-details="auto"
                          hint="The method used to calculate interest"
                          v-model="form.interestCalculationMethod"
                          required
                          :rules="[rules.requiredField]"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-select
                          label="Days in year*"
                          :items="daysInYearOptions"
                          item-text="text"
                          data-cy="days-in-year"
                          item-value="value"
                          clearable
                          hide-details="auto"
                          hint="The setting for number of days in year to use to calculate interest"
                          v-model="form.daysInYear"
                          required
                          :rules="[rules.requiredField]"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-switch
                          hint="Identifies if creation of account is auto activated"
                          v-model="form.autoApproval"
                          data-cy="auto-approval"
                          :label="`Auto Approval: ${
                            form.autoApproval ? form.autoApproval.toString() : 'false'
                          }`"
                        ></v-switch>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <v-flex class="text-right">
                    <v-btn
                      class="mr-2"
                      @click="form.accountType === 'CREDIT' ? (e1 = 3) : (e1 = 2)"
                      color="warning"
                      >Back</v-btn
                    >
                    <v-btn color="primary" data-cy="save-wallet" @click="save"
                      >Save</v-btn
                    >
                  </v-flex>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>

          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" data-cy="close-wallet" text v-on:click="close"
            >Close</v-btn
          >
          <!-- <v-btn color="blue darken-1" text v-on:click="save">Save</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import {
  CURRENCIES,
  INTEREST_COUMPOUNDING_PERIODS,
  INTEREST_POSTING_PERIODS,
  INTEREST_CALCULATION_METHODS,
  DAYS_IN_YEAR_OPTIONS,
} from "@/constants/savings_product.js";
import API from "@/api/API.js";

export default {
  props: {
    isShow: Boolean,
    formType: String,
    loadingDialog: Boolean,
    selectedItem: Object,
    types: Array,
    wallets: Array,
  },
  computed: {
    populatedForm() {
      return {
        id: this.form.id,
        name: this.form.name,
        shortName: this.form.shortName,
        accountType: this.form.accountType,
        description: this.form.description,
        imageUrl: this.form.imageUrl,
        autoApproval: this.form.autoApproval,
        currency: this.form.currency,
        decimalPlaces: this.form.decimalPlaces,
        multiplesOf: this.form.multiplesOf,
        nominalAnnualInterest: this.form.nominalAnnualInterest,
        interestCompoundingPeriod: this.form.interestCompoundingPeriod,
        interestPostingPeriod: this.form.interestPostingPeriod,
        interestCalculationMethod: this.form.interestCalculationMethod,
        daysInYear: this.form.daysInYear,
        formType: this.formType,
        jobs: this.form.jobs,
        linkedWallets: this.form.linkedWallets,
      };
    },
  },
  watch: {
    isShow: {
      handler(value) {
        this.dialog = value;
      },
    },
    loadingDialog: {
      handler(value) {
        this.loading = value;
      },
    },
    dialog: {
      handler(value) {
        if (value === true) {
          this.initializeForm();
        }
      },
    },
    types: {
      handler(value) {
        this.productTypes = value ? value : [];
      },
    },
    wallets: {
      handler(value) {
        this.linkedWallets = value ? value : [];
      },
    },
    availableLinkedJobs: {
      handler(value) {
        //console.log("available linked jobs: ", value);
        this.availableLinkedJobs = value;
      },
    },
    "form.jobs": {
      handler(after, before) {
        var selected = [];
        for (var i = 1; i < after.length; i++) {
          selected = selected.concat(this.form.jobs[i]);
        }
        for (var i = 1; i < this.availableLinkedJobs.length; i++) {
          let filteredJobs = this.linkedJobs.filter((job) => {
            if (this.form.jobs[i]) {
              return !selected.includes(job.id) || this.form.jobs[i].includes(job.id);
            } else {
              return !selected.includes(job.id);
            }
          });
          this.$set(this.availableLinkedJobs, i, filteredJobs);
        }
        let afterFilteredJobs = this.linkedJobs.filter((job) => {
          return !selected.includes(job.id);
        });
        this.$set(this.availableLinkedJobs, after.length, afterFilteredJobs);
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      linkedWalletFields: 1,
      accountTypeItems: [
        { text: "Regular Wallet", value: "REGULAR_WALLET" },
        { text: "Team Wallet", value: "TEAM_WALLET" },
        { text: "Master Wallet", value: "MASTER_WALLET" },
        { text: "Super Wallet", value: "SUPER_WALLET" },
        { text: "Voucher", value: "VOUCHER" },
        { text: "Credit", value: "CREDIT" },
        { text: "Loyalty", value: "LOYALTY" },
      ],
      dialog: this.isShow ?? false,
      loading: this.loadingDialog ?? false,
      productTypes: this.types ? this.types : [],
      linkedJobs: [],
      availableLinkedJobs: [],
      linkedWallets: this.wallets ? this.wallets : [],
      form: {},
      rules: {
        requiredField: (v) => !!v || "Required field.",
        requiredSelection: (v) =>
          (!!v && v.length > 0) || "Required at least one selection",
        properEmail: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid. Ex. juandelacruz@apollo.com.ph",
        maxLength: (v) => (v && v.length <= 4) || "Max 4 characters",
        positiveIntegers: (v) =>
          (!isNaN(v) && /^[0-9]*$/.test(v)) || "Must be positive integers only",
      },
      currencies: CURRENCIES,
      interestCompoundingPeriods: INTEREST_COUMPOUNDING_PERIODS,
      interestPostingPeriods: INTEREST_POSTING_PERIODS,
      interestCalculationMethods: INTEREST_CALCULATION_METHODS,
      daysInYearOptions: DAYS_IN_YEAR_OPTIONS,
      e1: 1,
      isFormComplete: false,
    };
  },
  mounted() {
    this.initializeForm();
  },
  methods: {
    jobClicked(value) {
      // console.log("n value: ", value);
      // console.log(this.availableLinkedJobs);
      // console.log(this.availableLinkedJobs[value]);
    },
    close() {
      this.dialog = false;
      this.$refs.formEntry.reset();
      this.$refs.formCard.$el.scrollIntoView({
        behavior: "smooth",
      });
      this.$emit("close-dialog", this.formType);
    },
    next(item) {
      if (item == 1 && this.$refs.formEntryOne.validate()) {
        this.e1 = 2;
        if (this.form.accountType === "CREDIT") {
          this.loading = true;
          this.queryJobLinks();
        }
      } else if (
        this.form.accountType !== "CREDIT" &&
        item == 2 &&
        this.$refs.formEntryTwo.validate()
      ) {
        this.e1 = 3;
      } else if (
        this.form.accountType === "CREDIT" &&
        item == 2 &&
        this.$refs.formEntryFour.validate()
      ) {
        this.e1 = 3;
      } else if (item == 3 && this.$refs.formEntryTwo.validate()) {
        this.e1 = 4;
      }
    },
    async queryJobLinks() {
      try {
        const listResponse = await API.getAllJobs();
        if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`);
        } else {
          // convert object to array
          this.linkedJobs = listResponse;
          //this.availableLinkedJobs[1] = this.linkedJobs;
          this.$set(this.availableLinkedJobs, 1, this.linkedJobs);
          this.loading = false;
          if (this.formType === "UPDATE") {
            var selectedJob = [];
            var selectedWallet = [];
            var linkedWallets = {};
            if (this.selectedItem.linkedWallets) {
              linkedWallets = JSON.parse(this.selectedItem.linkedWallets);
            }
            if (this.selectedItem.jobs) {
              this.linkedWalletFields = this.selectedItem.jobs.length;
              for (var i = 0; i < this.selectedItem.jobs.length; i++) {
                const linkedId = i + 1;
                //this.availableLinkedJobs[linkedId] = this.linkedJobs;
                this.$set(this.availableLinkedJobs, linkedId, this.linkedJobs);
                const jobId = this.selectedItem.jobs[i].id;
                //selectedJob[linkedId] = [jobId];
                this.$set(selectedJob, linkedId, [jobId]);
                console.log(selectedJob);
                const walletIds = linkedWallets[jobId]
                  ? linkedWallets[jobId].walletId
                  : [];
                //selectedWallet[linkedId] = walletIds;
                this.$set(selectedWallet, linkedId, walletIds);
              }
            }
            this.form.jobs = selectedJob;
            this.form.linkedWallets = selectedWallet;
          }
        }
      } catch (e) {
        console.log(e);
        //display error prompt
        this.loading = false;
      }
    },
    save() {
      if (this.$refs.formEntry.validate()) {
        this.isFormComplete = true;
        this.$emit("save-savings-product", this.populatedForm);
        this.$refs.formCard.$el.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    addLinkedWallets() {
      this.linkedWalletFields++;
      var selected = [];
      for (var i = 1; i < this.form.jobs.length; i++) {
        selected = selected.concat(this.form.jobs[i]);
      }
      const filterJobs = this.linkedJobs.filter((job) => {
        return !selected.includes(job.id);
      });
      this.$set(this.availableLinkedJobs, this.linkedWalletFields, filterJobs);
    },
    removeLinkedWallets() {
      this.linkedWalletFields--;
      let diff = this.availableLinkedJobs.length - this.linkedWalletFields;
      while (diff > 1) {
        this.availableLinkedJobs.pop();
        diff = diff - 1;
      }
      diff = this.form.linkedWallets.length - this.linkedWalletFields;
      while (diff > 1) {
        this.form.linkedWallets.pop();
        diff = diff - 1;
      }
      diff = this.form.jobs.length - this.linkedWalletFields;
      while (diff > 1) {
        this.form.jobs.pop();
        diff = diff - 1;
      }
    },
    resetForm() {
      this.$refs.formEntry.reset();
    },
    initializeForm() {
      this.e1 = 1;
      if (this.formType === "CREATED") {
        this.form = {
          name: undefined,
          shortName: undefined,
          accountType: undefined,
          description: undefined,
          imageUrl: undefined,
          autoApproval: undefined,
          currency: undefined,
          decimalPlaces: undefined,
          multiplesOf: undefined,
          nominalAnnualInterest: undefined,
          interestCompoundingPeriod: undefined,
          interestPostingPeriod: undefined,
          interestCalculationMethod: undefined,
          daysInYear: undefined,
          jobs: [],
          linkedWallets: [],
        };
      } else if (this.formType === "UPDATE" && this.selectedItem) {
        var selectedJob = [];
        var selectedWallet = [];
        // var linkedWallets = {};
        // if (this.selectedItem.linkedWallets) {
        //   linkedWallets = JSON.parse(this.selectedItem.linkedWallets);
        // }
        // if (this.selectedItem.jobs) {
        //   this.linkedWalletFields = this.selectedItem.jobs.length;
        //   for (var i = 0; i < this.selectedItem.jobs.length; i++) {
        //     const linkedId = i + 1;
        //     const jobId = this.selectedItem.jobs[i].id;
        //     selectedJob[linkedId] = [jobId];
        //     console.log(selectedJob);
        //     const walletIds = linkedWallets[jobId] ? linkedWallets[jobId].walletId : [];
        //     selectedWallet[linkedId] = walletIds;
        //   }
        // }
        this.form = {
          id: this.selectedItem.id,
          name: this.selectedItem.name,
          shortName: this.selectedItem.shortName,
          accountType: this.selectedItem.accountType,
          description: this.selectedItem.description,
          imageUrl: this.selectedItem.imageUrl,
          autoApproval: this.selectedItem.autoApproval,
          currency: this.selectedItem.currency,
          decimalPlaces: this.selectedItem.decimalPlaces.toString(),
          multiplesOf: this.selectedItem.multiplesOf.toString(),
          nominalAnnualInterest: this.selectedItem.nominalAnnualInterest.toString(),
          interestCompoundingPeriod: this.selectedItem.interestCompoundingPeriod,
          interestPostingPeriod: this.selectedItem.interestPostingPeriod,
          interestCalculationMethod: this.selectedItem.interestCalculationMethod,
          daysInYear: this.selectedItem.daysInYear,
          jobs: selectedJob,
          linkedWallets: selectedWallet,
        };
      }
    },
  },
};
</script>

<style scoped>
.stepper-1-btns {
  margin-top: 30px !important;
}

.stepper-2-btns {
  margin-top: 74px !important;
}
</style>
